import logo from './logo.svg';
import './App.css';
import React, {useEffect, useRef} from 'react'


function App() {

  useEffect(()=>{
    //let x = window.Module.mono_bind_static_method("[Sample] Sample:Hello");
  },[])

  const vidRef = useRef(null);
  
  const playVideo = () => {
    let vid= document.getElementById("vid1")
    vid.play();
    vid.hidden=false;
  }

  const callWasm = () =>{
    window.GetWasmMethod("text1")
  }


  //console.log(window.AppInit);
  //console.log(window.MonoOnject);
  console.log("sadsdsadsad",window);
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> 
       <video id="vid1" hidden="true" width="450" ref={vidRef} src="videoplayback.mp4" type="video/mp4"></video>
       <button onClick={()=>{playVideo()}}>PLAY</button>
       <textarea id="text1"/>
       <button onClick={()=>{callWasm()}}>call wasm method</button>
      </header>
     
    </div>
  );
}

export default App;
